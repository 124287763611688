import React, { Component } from "react";
import firebase from "firebase/app";
import moment from "moment";
import "firebase/firestore";
import "firebase/analytics";
import { firebaseConfig } from "../components/Firebase/firebase";
import Loader from "react-loader-spinner";
import Switch from "react-switch";
import SubBanner from "components/SubBanner";
import { Label, Input, FormGroup, Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import qs from "query-string";
import { getUsersBuyers } from "../Services/BuyerService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/offers.css";
import OfferEmailTemplateForAdmin from "../components/emailTemplates/OfferEmailTemplateForAdmin";
import ReactDOMServer from "react-dom/server";
import { saveActivity } from "../Services/Activity";
import offerDetails from "../templates/email/offer-details";
import ScoreEmail from "../templates/email/score-email";
import RecordVideo from "../components/VideoRecord";
import RecordAudio from "../components/AudioRecord";
import AiScoreDoughnutChart from "../components/Charts/AiScoreDoughnutChart";
import ScoringModal from "../components/ScoringModal";
// import swirlArrow from "../assets/icons/swirly-arrow.png";
import swirlArrow from "../assets/icons/up-arrow.png";

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const { cloudFunctionHost } = firebaseConfig;

let { logEvent } = firebase.analytics();
class MakeOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideSubmitButton: false,
      inputs: {
        buyerName: "",
        buyer: "",
        documents: [],
      },
      offerType: "Written",
      bac_type: "Percentage",
      agentToggle: 0,
      loading: false,
      doesExpire: "no",
      id: null,
      offerId: null,
      isUploading: false,
      page: "prep_offer",
      offerTypes: [
        { value: "TraditionalFinance", label: "Traditional Finance" },
        { value: "InstantOffer", label: "Instant Offer" },
        { value: "Investor", label: "Investor" },
        { value: "SellerFinance", label: "Seller Finance" },
      ],
      otherBuyers: [],
      buyerOptions: [],
      inProgress: false,
      updateMode: false,
      recordAudioModal: false,
      propertyAddress: props.history?.location?.state?.propertyAddress,
      signupCheckModal: false,
      recordVideoModal: false,
      startRecording: false,
      signupCheckResponse: false,
      hideMediaUploadButtons: false,
      password: null,
      confirmPassword: null,
      registerError: null,
      showLearnScoringModal: false,
      previewOfferScoreModal: false,
      allowSmsEmailNoti: false,
      showOfferScoreWidget: false,
      emailInputs: "",
      videoUploading: false,
      audioUploading: false,
      emailSeller: "",
      emailId: "",
      videoSource: null,
      videoFileUrl: null,
      audioFileUrl: null,
      attachedFileUrl: null,
      globalOfferDataAccess: false,
      audioMsgDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
      isOfferUpdate: new URLSearchParams(props.location.search).get("offer"),
      lenderInfo: false,
    };
    this.mainForm = React.createRef();
    this.videoRef = React.createRef();
  }

  async componentWillMount() {
    this.setState((s) => ({ loading: !s.loading }));
    let { match, user } = this.props;
    if (!user) user = {};
    const { params } = match;
    const { id } = params;
    if (!id) {
      this.props.history.goBack();
      return;
    }
    this.setState({
      emailId: id,
    });
    const db = firebase.firestore();
    const sellerRef = db.collection("sellers").doc(id);
    sellerRef
      .get()
      .then(async (doc) => {
        const data = doc.data();
        const { street_number, street_name, city, state, zip_code } = data;
        const address = `${street_number} ${street_name}, ${city}, ${state} ${zip_code}`;
        const { loading, inputs } = this.state;
        const search = qs.parse(this.props.location.search);
        const { offer: offerId } = search;

        this.setState({
          offerId: offerId,
        });

        if (!this.state.propertyAddress)
          this.setState({
            propertyAddress: `${street_number} ${street_name}, ${city}, ${state}, ${zip_code}`,
          });

        if (this.mounted) {
          let offer = {};
          let updateMode = false;
          if (offerId) {
            const doc = await db.collection("offers").doc(offerId).get();
            offer = { ...doc.data(), id: offerId };
            updateMode = true;
            saveActivity({
              text: `An Offer has been resubmitted.`,
              userName: user.full_name || user.realtor_name || offer.agentName,
              userId: user?.uid || offer.seller_id,
            });
          }
          this.setState({
            verbalOfferTerms: {
              is_verbal_offer_terms_allowed: data.verbal_offer_terms_allowed,
              minimum_offer_price: data.minimum_offer_price,
              closing_date: data.closing_date,
              deposit_money: data.deposit_money
            },
            bac_type: offer?.bac_type ? offer?.bac_type : "Percentage",
            offerType: data.verbal_offer_terms_allowed ? offer?.offerType ? offer?.offerType : 'Written' : 'Written',
            doesExpire: offer?.offer_expire_date && offer.offer_expire_date !== "" ? "yes" : "no",
            id,
            loading,
            address,
            updateMode,
            seller: data,
            videoFileUrl: offer.offer_video,
            audioFileUrl: offer.offer_audio,
            inputs: {
              ...inputs,
              ...offer,
              address,
              agentName: offer.agentName || user.full_name || user?.realtor_name,
              phoneNumber: offer.phoneNumber || user.phone || user.phoneNumber || user?.realtor_phone,
              email: offer.email || user.user_email || offer.buyerEmail || user?.realtor_email,
              offer_expire_date: offer.offer_expire_date,
            },
          });
        }
      })
      .catch((error) => {
        this.setState((s) => ({ loading: !s.loading }));
        console.error(error);
      });

    const { full_name, lender_name, builder_name, uid, role } = user || {};
    const name = full_name || lender_name || builder_name || "";
    const { inputs } = this.state;

    if (role === "realtor") {
      let buyerOptions = await getUsersBuyers(user);
      buyerOptions = buyerOptions.map((b) => {
        return {
          value: b.id,
          label: b.data().buyer_name,
        };
      });
      this.setState({ buyerOptions });
    } else {
      this.setState({
        inputs: {
          ...inputs,
          buyer: uid,
          buyerName: name,
        },
      });
    }
  }

  signupCheckModalToggle = () => {
    this.setState({
      signupCheckModal: !this.state.signupCheckModal,
    });
  };

  previewOfferScoreModalToggle = () => {
    this.setState({
      previewOfferScoreModal: !this.state.previewOfferScoreModal,
    });
  };

  recordVideoModalToggle = () => {
    this.setState({
      recordVideoModal: !this.state.recordVideoModal,
    });
  };

  recordAudioModalToggle = () => {
    this.setState({
      recordAudioModal: !this.state.recordAudioModal,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  async componentDidMount() {
    this.mounted = true;
  }

  chancelRequest = () => {
    const { id } = this.state;
    this.props.history.push(`/sellers/${id}`);
  }; 

  onChange = ({ target }) => {
    const { name, value } = target;
    const { inputs } = this.state;
  
    let { purchasePrice, downPayment, financeAmount } = inputs;
  
    // Sanitize input for specific numeric fields
    const numericFields = [
      "purchasePrice",
      "downPayment",
      "financeAmount",
      "earnestMoney",
      "optionFee",
      "sellerConcessions",
      "residentialServiceContract",
      "optionDays",
      "leasebackDays",
    ];
  
    // Sanitize value only if it's a numeric field
    let sanitizedValue = numericFields.includes(name) ? value.replace(/\D/g, "") : value;
  
    purchasePrice = parseInt(purchasePrice || "0");
    downPayment = parseInt(downPayment || "0");
  
    // Existing logic for finance amount
    if (name === "downPayment") {
      financeAmount = purchasePrice - parseInt(sanitizedValue || "0");
    }

    if (name === "bac") {
      sanitizedValue = value.replace(/%/g, "");
    }  
  
    if (name === "purchasePrice") {
      financeAmount = parseInt(sanitizedValue || "0") - downPayment;
    }
  
    // Existing logic for closing date and number of days
    let closingDate = inputs.closingDate;
    let numberOfDays = inputs.numberOfDays;
  
    if (name === "closingDate") {
      const start = moment(sanitizedValue);
      const end = moment();
  
      numberOfDays = sanitizedValue === moment().format("YYYY-MM-DD") ? 0 : start.diff(end, "days") + 1;
      closingDate = sanitizedValue;
    }
  
    if (name === "numberOfDays") {
      numberOfDays = sanitizedValue;
      closingDate = moment().add(parseInt(sanitizedValue || "0"), "days").format("YYYY/MM/DD");
    }
    // Update state with sanitized or original value
    this.setState({
      inputs: {
        ...inputs,
        [name]: sanitizedValue,
        financeAmount,
        numberOfDays,
        closingDate,
      },
    });
  }; 

  notifyAllBuyers = async (buyers, options) => {
    const { seller } = this.state;
    const { street_number, street_name } = seller;
    const { id, financingType, purchasePrice, closingDate, offerUpdated, doesExpire, offer_expire_date } = options;
    const sendSMS = firebase.functions().httpsCallable("sendSMS");
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    if(!buyers.length) return;
    for (const buyer of buyers) {
      const { buyerName, buyersEmail, buyerPhoneNumber } = buyer;
  
      // SMS Notification
      try {
        await sendSMS({
          to: `+1${buyerPhoneNumber}`,
          body: `OFFER ${offerUpdated ? "UPDATED" : "RECEIVED"} ${moment().format("YYYY-MM-DD hh:mmA")}, ${street_number} ${street_name}. ${buyerName},
          Finance: ${financingType}
          Price: ${purchasePrice}
          Closing: ${closingDate}
          Expire-Date: ${doesExpire === "yes" ? offer_expire_date : "N/A"}
          Details: https://homematchx.com/offers/${id}`,
        });
      } catch (err) {
        console.error(`Error sending SMS to ${buyerPhoneNumber}:`, err);
      }

      // Email Notification
      try {
        const offerDetailsBody = offerDetails(
          {
            ...options,
            userName: buyerName,
          },
          {
            ...seller,
            userName: seller.seller_name,
          },
          id
        );
  
        await sendEmail({
          subject: `You've received a new offer - Homematchx`,
          email: buyersEmail,
          body: offerDetailsBody,
        });
      } catch (err) {
        console.error(`Error sending email to ${buyersEmail}:`, err);
      }
    }
  };

  sendOfferEmail = async (options) => {
    const { user } = this.props;
    const { buyerName, id, financingType, purchasePrice, closingDate, offerUpdated } = options;    
    const isBuyer = qs.parse(this.props.location.search);
    const { seller, inputs } = this.state;
    const buyer_phone = inputs.phoneNumber || user.phone || user.realtor_phone || user.lender_phone || user.builder_phone;
    const { represendtedBy, seller_phone } = seller;
    const { secondPhoneNumber, thirdPhoneNumber } = inputs;
    const cc = [];
    const { street_number, street_name } = seller;
    if (represendtedBy && represendtedBy !== "Self Represented") {
      cc.push(represendtedBy);
    }

    const sendSMS = firebase.functions().httpsCallable("sendSMS");
    try {
      await sendSMS({
        to: `+1${seller_phone}`,
        body: `OFFER ${offerUpdated ? isBuyer?.isBuyer ? "RESUBMITTED" : "UPDATED" : "RECEIVED"} ${moment().format("YYYY-MM-DD hh:mmA")}, ${street_number} ${street_name}. ${buyerName},
Finance: ${financingType}
Price: ${purchasePrice}
Closing: ${closingDate}
Expire-Date: ${this.state.doesExpire === "yes" ? inputs.offer_expire_date : "N/A"}
Details: https://homematchx.com/offers/${id}`,
      });
    } catch (err) {
      console.log("Sending SMS Error: ", err);
    }

    if (secondPhoneNumber) {
      try {
        await sendSMS({
          to: `+1${secondPhoneNumber}`,
          body: `OFFER ${offerUpdated ? isBuyer?.isBuyer ? "RESUBMITTED" : "UPDATED" : "RECEIVED"} ${moment().format("YYYY-MM-DD hh:mmA")}, ${street_number} ${street_name}. ${buyerName},
Finance: ${financingType}
Price: ${purchasePrice}
Closing: ${closingDate}
Expire-Date: ${this.state.doesExpire === "yes" ? inputs.offer_expire_date : "N/A"}
Details: https://homematchx.com/offers/${id}`,
        });
      } catch (err) {
        console.log("Sending SMS Error: ", err);
      }
    }

    if (thirdPhoneNumber) {
      try {
        await sendSMS({
          to: `+1${thirdPhoneNumber}`,
          body: `OFFER ${offerUpdated ? isBuyer?.isBuyer ? "RESUBMITTED" : "UPDATED" : "RECEIVED"} ${moment().format("YYYY-MM-DD hh:mmA")}, ${street_number} ${street_name}. ${buyerName},
Finance: ${financingType}
Price: ${purchasePrice}
Closing: ${closingDate}
Expire-Date: ${this.state.doesExpire === "yes" ? inputs.offer_expire_date : "N/A"}
Details: https://homematchx.com/offers/${id}`,
        });
      } catch (err) {
        console.log("Sending SMS Error: ", err);
      }
    }

    try {
      await sendSMS({
        to: `+1${buyer_phone}`,
        body: `OFFER ${offerUpdated ? isBuyer?.isBuyer ? "RESUBMITTED" : "UPDATED" : "SENT"} ${moment().format("YYYY-MM-DD hh:mmA")}, ${street_number} ${street_name}. ${buyerName},
Finance: ${financingType}
Price: ${purchasePrice}
Closing: ${closingDate}
Expire-Date: ${this.state.doesExpire === "yes" ? inputs.offer_expire_date : "N/A"}
Details: https://homematchx.com/offers/${id}

To update offer please visit https://homematchx.com/makeOffer/${options.seller}?offer=${id}&update=1`,
      });
    } catch (err) {
      console.log("Sending SMS Error: ", err);
    }

    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    const offerDetailsBody = offerDetails(
      {
        ...inputs,
        bac_type: this.state.bac_type,
        userName: user?.full_name || user?.realtor_name || inputs.agentName,
      },
      {
        ...seller,
        userName: seller.seller_name,
      },
      id
    );

    await sendEmail({
      subject: offerUpdated 
      ? (isBuyer?.isBuyer ? `An offer has been resubmitted - Homematchx` : `An offer has been updated - Homematchx`) 
      : `You've received a new offer - Homematchx`,
      email: seller.seller_email,
      body: offerDetailsBody,
    });
    saveActivity({
      text: `An sms notification has been sent.`,
      userName: user?.full_name || user?.realtor_name || inputs.agentName,
      userId: user?.uid || seller.seller_id,
    });
  };

  sendOfferSMSToSeller = (offer) => {
    // const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
  };

  sendOfferEmailToAdmin = async (offer) => {
    delete offer.documents;

    const { user } = this.props;
    const { seller } = this.state;
    const buyer_email = offer.email;
    const { seller_name, seller_email, seller_phone, street_number, street_name, city, state, zip_code } = seller;
    const buyer_phone = offer.phoneNumber || user.phone || user.realtor_phone || user.lender_phone || user.builder_phone;
    const data = {
      buyer_name: offer.buyerName,
      buyer_email,
      buyer_phone,
      seller_name,
      seller_email,
      seller_phone,
      ...offer,
    };

    logEvent("send_admin_offer_email");

    const { offerExpires, buyerName, price, address } = offer;
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    const body = ReactDOMServer.renderToStaticMarkup(
      OfferEmailTemplateForAdmin({
        addressLineOne: `${street_number} ${street_name}`,
        addressLineTwo: `${city}, ${state} ${zip_code}`,
        expires: `This offer expires on ${offerExpires}`,
        heading: `${buyerName} made an offer to ${address}`,
        price: `$${price}`,
        offer: data,
      })
    );
    await sendEmail({ subject: address, email: seller_email, body });
    await sendEmail({ subject: address, email: buyer_email, body });
  };

  onSubmit = async (e, userCreated = false, newUser) => {
    if (e) e.preventDefault();
    const isBuyer = qs.parse(this.props.location.search);
    const { inputs, file, seller, updateMode, doesExpire, offerType, otherBuyers, bac_type } = this.state;
    const { user, match, history } = this.props;
    if (user?.hasOwnProperty("verifiedUser") && !user.verifiedUser) {
      const db = firebase.firestore();
      await db.collection("realtors").doc(user.uid).update({
        verifiedUser: true,
      });
    }

    const documents = inputs.documents;
    const newInputs = { ...inputs, offerType };
    newInputs.seller_id = this.state.seller.seller_id || this.state.seller.ListingKey;
    newInputs.featured_image = this.state.seller.featured_image;
    
    if(offerType === 'Verbal') newInputs.financeAmount = '';
    if (!inputs.documents.length)
      return toast.error(`Please upload documents before submitting offer.`, {
        position: toast.POSITION.TOP_RIGHT,
      });

    if (!inputs.titlePolicyExpense)
      return toast.error(`Title policy expense is required.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      
    const { expected_sales_price, projectedPrice, current_value, bedrooms, bathrooms, sqft, listing_price } = seller;

    newInputs.actual_price = parseInt(expected_sales_price || projectedPrice || current_value || listing_price);
    newInputs.bedrooms = bedrooms;
    newInputs.bathrooms = bathrooms;
    newInputs.sqft = sqft || 0;
    newInputs.bac_type = bac_type;
    if (bac_type === "Percentage" && inputs.bac > 100) {
      return toast.error(`Buyers Agent Compensation cannot exceed 100% when type is set to Percentage.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if(otherBuyers.length) newInputs.otherBuyers = otherBuyers;

    if (!user && !userCreated) {
      return this.signupCheckModalToggle();
    }

    if (doesExpire === "yes" && !newInputs.offer_expire_date)
      return toast.error(`Offer Expire Date is required.`, {
        position: toast.POSITION.TOP_RIGHT,
      }); 
    if (doesExpire === "no") {
      newInputs.offer_expire_date = null;
    }
    this.setState({
      inProgress: true,
    });
  
    if (updateMode) {
      try {
        if (!newInputs.buyer) newInputs.buyer = "";
        if (!newInputs.financeAmount) newInputs.financeAmount = 0;
        if (!newInputs.numberOfDays) newInputs.numberOfDays = 0;
        if (!newInputs.offer_audio) {
          newInputs.offer_audio = "";
        } else {
          newInputs.offer_audio = this.state.audioFileUrl;
        }
        if (!newInputs.offer_video) {
          newInputs.offer_video = "";
        } else {
          newInputs.offer_video = this.state.videoFileUrl;
        }
        newInputs.globalOfferDataAccess = this.state.globalOfferDataAccess;
        if (!newInputs.globalOfferDataAccess) {
          this.setState({
            inProgress: false,
          });
        
          return toast.error(`Please give contsent to show offer publically.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
          
        await this.updateOffersWithScores(newInputs.seller, inputs);
        const db = firebase.firestore();

        const docRef = db.collection("offers").doc(newInputs.id);
        await docRef.update(newInputs);
        if(this.state.allowSmsEmailNoti) {
          await db.collection("notifications").add({
            user_id: this.state.seller.seller_id,
            property_id: newInputs.id,
            text: `An offer has been updated.`,
            type: `offer`,
            createdAt: moment().utc().unix(),
          });
        } 

        if(inputs?.status !== 'Terminated') {
          if(this.state.allowSmsEmailNoti) {
            await this.sendOfferEmail({
              ...newInputs,
              offerUpdated: true,
            });
          }
        }
        const successMessage = "Your offer request has been resubmitted";
        toast.success(successMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!user) history.push(`/sellers/${newInputs.seller}`);
        else history.push(`/offers/${inputs.id}`);
        return this.setState({ inProgress: false });
      } catch (error) {
        this.setState({ inProgress: false });
        console.log("Error", error);
      }
    }
    newInputs.status = "In Review";
    if (newInputs.without_buyer_email) {
      newInputs.without_agent = true;
    }

    const { seller_email, represendtedBy, lender_email, owner } = seller;

    // if (user?.realtor_email !== "inkrealtygroup@gmail.com") await this.sendOfferEmailToAdmin(newInputs);
    const search = [];
    if (seller_email || represendtedBy) {
      search.push(seller_email.toLowerCase() || represendtedBy);
    }

    if (represendtedBy && represendtedBy !== "Self Represented") {
      search.push(represendtedBy);
    }

    if (lender_email) {
      search.push(lender_email);
    }

    if (owner) {
      search.push(owner);
    }

    if (user && user.email) search.push(user.email);
    else search.push(inputs.email);

    newInputs.status = "In Review";
    newInputs.sender = user ? user.uid : userCreated && newUser ? newUser.uid : null;
    newInputs.buyerEmail = user ? user?.email?.toLowerCase() : inputs?.email?.toLowerCase();
    newInputs.sellerEmail = seller_email.toLowerCase() || represendtedBy;
    newInputs.documents = documents;
    newInputs.seller = match.params.id;
    newInputs.featured_image = seller.featured_image || { url: null };
    newInputs.search = search;
    newInputs.purchasePrice = parseInt(inputs.purchasePrice);
    newInputs.createdAt = moment().utc().unix();
    newInputs.actual_price = parseInt(expected_sales_price || projectedPrice || current_value || listing_price);
    newInputs.globalOfferDataAccess = this.state.globalOfferDataAccess;
    if (this.state.videoFileUrl) newInputs.offer_video = this.state.videoFileUrl;
    if (this.state.audioFileUrl) newInputs.offer_audio = this.state.audioFileUrl;
    if (file) {
      newInputs.fileExtension = file.name.split(".").pop();
    }

    if (!newInputs.globalOfferDataAccess)
      return toast.error(`Please give contsent to show offer publically.`, {
        position: toast.POSITION.TOP_RIGHT,
      });

    try {
      const db = firebase.firestore();
      const resourceId = inputs.buyer ? inputs.buyer.value : null;
      const currentDate = new Date().toDateString();

      if (resourceId) {
        await db
          .collection("users")
          .doc(user.uid)
          .collection("notes")
          .doc(resourceId)
          .set({
            data: firebase.firestore.FieldValue.arrayUnion(`On ${currentDate} -  We made an offer on ${inputs.address}`),
          });
      }
      if (!newInputs.agentName) newInputs.agentName = "";
      if (!newInputs.buyerEmail) newInputs.buyerEmail = "";
      if (!newInputs.email) newInputs.email = "";
      if (!newInputs.buyer) newInputs.buyer = "";
      if (!newInputs.sender) newInputs.sender = "";
      
      const doc = await db.collection("offers").add(newInputs);
      newInputs.id = doc.id;
      if (newInputs.comments) {
        const comment = {
          text: newInputs.comments,
          name: newInputs.agentName,
          commentedAt: new Date().getTime(),
        };

        firebase
          .database()
          .ref(`/OfferComments/${newInputs.id}`)
          .push(comment, (err) => {
            console.log(err);
            if (err) alert("Unexpected error occurred while saving comment. Please try again later.");
          });
      }
 
      const successMessage = "Your offer request has been submitted";
      if (this.state.seller.seller_id) {
        await db.collection("notifications").add({
          user_id: this.state.seller.seller_id,
          property_id: newInputs.id,
          text: `You have received a new offer to your property.`,
          type: `offer`,
          createdAt: moment().utc().unix(),
        });
      }

      if(inputs.status !== 'Not Accepted' || inputs.status !== 'Terminated') {
        await this.notifyAllBuyers([{
          buyerName: newInputs.buyerName,
          buyersEmail: newInputs.buyersEmail,
          buyerPhoneNumber: newInputs.buyerPhoneNumber
        }, ...otherBuyers], newInputs);
      }
   
      await this.updateOffersWithScores(newInputs.seller, inputs);
      toast.success(successMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });

      if(inputs?.status !== 'Not Accepted' && inputs?.status !== 'Terminated') {
        await this.sendOfferEmail(newInputs);
      }
      this.setState({ inProgress: false });
      this.setState({
        videoFileUrl: null,
        audioFileUrl: null,
      });
      if (user) return history.push("/dashboard");
      else return history.push("/sellers/" + newInputs.seller);
    } catch (error) {
      this.setState({ inProgress: false });
      console.log("Error", error);
    }
  };

  updateOffersWithScores = async (sellerId, inputs) => {
    try {
      const db = firebase.firestore();
      const { offers, sellerListing } = await this.fetchOffersOfSeller(sellerId);
      if (!sellerListing) {
        console.error("Seller listing not found.");
        return;
      }
      const listingPrice = parseFloat(sellerListing.listing_price) || parseFloat(sellerListing.current_value);
      // Function to calculate score for each offer
      const calculateScore = (offer) => {
        const purchasePrice = parseFloat(offer.purchasePrice || 0);
        let sellerConcessions = parseFloat(offer.sellerConcessions || 0);
        if (/^\d+(\.\d+)?$/.test(offer.sellerConcessions)) {
          sellerConcessions = parseFloat(offer.sellerConcessions);
        } else {
          sellerConcessions = 0;
        }
        const serviceContract = parseFloat(offer.residentialServiceContract || 0); // New field
        const netPurchasePrice = purchasePrice - sellerConcessions - serviceContract; // Net Purchase Price

        const earnestMoney = parseFloat(offer.earnestMoney || 0);
        const closingDate = new Date(offer.closingDate);
        const today = new Date();
        const optionDays = parseInt(offer.optionDays || 0);
        const titlePolicyExpense = offer.titlePolicyExpense;
        // 1. Purchase Price Score (50 Base + Bonus)
        // const basePriceScore = Math.min((purchasePrice / listingPrice) * 50, 50);
        // const bonusPriceScore = Math.max((purchasePrice - listingPrice) / 10000, 0) * 2;

        const basePriceScore = Math.min((netPurchasePrice / listingPrice) * 54.5, 54.5); // Updated to 54 base
        const bonusPriceScore = Math.max((netPurchasePrice - listingPrice) / 5000, 0) * 2;
        const purchasePriceScore = basePriceScore + bonusPriceScore;
  
        // 2. Closing Date Score
        const closingDays = Math.ceil((closingDate - today) / (1000 * 60 * 60 * 24));
        let closingDateScore = 0;
        if (closingDays <= 15) closingDateScore = 20;
        else if (closingDays <= 30) closingDateScore = 15;
        else if (closingDays <= 45) closingDateScore = 10;
        else closingDateScore = 5;
  
        // 3. Earnest Money Score
        const earnestMoneyPercentage = (earnestMoney / listingPrice) * 100;
        let earnestMoneyScore = 0;
        if (earnestMoneyPercentage >= 5) earnestMoneyScore = 15;
        else if (earnestMoneyPercentage >= 3.5) earnestMoneyScore = 12;
        else if (earnestMoneyPercentage >= 1.5) earnestMoneyScore = 10;
        else earnestMoneyScore = 5;
  
        // 4. Option Period Score
        let optionPeriodScore = 0;
        if (optionDays === 0) optionPeriodScore = 10;
        else if (optionDays <= 3) optionPeriodScore = 8;
        else if (optionDays <= 5) optionPeriodScore = 5;
  
        // 5. Title Policy Responsibility Score
        // const titlePolicyScore = titlePolicyExpense === "Buyer" ? 5 : 0;
        const titlePolicyScore = titlePolicyExpense === "Buyer" ? 0.5 : 0;

        // Total Score
        const totalScore =
          purchasePriceScore +
          closingDateScore +
          earnestMoneyScore +
          optionPeriodScore +
          titlePolicyScore;

          return totalScore.toFixed(2);
      };
      const submittedOfferAiScore = calculateScore(inputs);
     // Step 1: Calculate scores for all offers

     const scoredOffers = offers.map((offer) => ({
        ...offer,
        aiScore: calculateScore(offer),
      }));
      const validOffers = scoredOffers.filter(offer => 
        !["Withdrawn", "Not Accepted", "Terminated"].includes(offer.status) &&
        offer.aiScore !== "NaN" && !isNaN(parseFloat(offer.aiScore))
      );
      
      let highestScore;
      if (validOffers.length > 0) {
        highestScore = Math.max(...validOffers.map((offer) => parseFloat(offer.aiScore)));
      }
    
      // Step 2: Find the highest score
      const submittedOfferAiScorePercentage = ((submittedOfferAiScore * 100) / highestScore).toFixed(2);
      const updatedOffers = scoredOffers.map((offer) => {
        const aiScorePercentage = ((offer.aiScore * 100) / highestScore).toFixed(2);
        return {
          ...offer,
          aiScorePercentage: parseFloat(aiScorePercentage),
        };
      });
      // Step 4: Update offers in Firestore
      for (const offer of updatedOffers) {
        await db
          .collection("offers")
          .doc(offer.id)
          .update({
            aiScore: offer.aiScore,
            aiScorePercentage: offer.aiScorePercentage,
          });
        // Check if this offer is below the highest score
        if (
          parseFloat(offer.aiScore) < submittedOfferAiScore &&
          offer.status !== "Accepted" && 
          offer.status !== "Executed" &&
          highestScore !== parseFloat(offer.aiScore) &&
          offer.whoseNo === true
        ) {

          if(inputs?.status !== 'Not Accepted' && inputs?.status !== 'Terminated') {
            try {
              const sendSMS = firebase.functions().httpsCallable("sendSMS");
              const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
              // Construct SMS and Email content
              const smsBody = `We received an offer on ${sellerListing.street_number} ${sellerListing.street_name}, increasing its probability of seller acceptance compared to your client’s offer. This may affect your client’s standing.

    If your client would like to improve their position, you can update their offer using the link below:

    Details: https://homematchx.com/makeOffer/${sellerListing.id}?offer=${offer.id}&update=1`;

              const emailSubject = `Update on Offer Activity for ${sellerListing.street_number} ${sellerListing.street_name}`;
              const emailBody = ScoreEmail(offer, sellerListing, offer.id);
            
              // Send SMS Notification
              if (offer.buyerPhoneNumber) {
                await sendSMS({
                    to: `+1${offer.buyerPhoneNumber}`,
                    body: smsBody,
                });
              }

              // Send Email Notification
              if (offer.buyerEmail) {
                await sendEmail({
                  subject: emailSubject,
                  email: offer.buyerEmail,
                  body: emailBody,
                });
              }
            } catch (err) {
                console.error(`Error sending notifications for offer ${offer.id}:`, err);
            }
          }
        }
      }

      console.log("All offers updated with scores and percentages.");
    } catch (error) {
      console.error("Error updating offers with scores:", error);
    }
  };

  fetchOffersOfSeller = async (sellerId) => {
    try {
      const db = firebase.firestore();
  
      // Fetch offers for the seller
      const offersSnapshot = await db.collection("offers").where("seller", "==", sellerId).get();
      const offers = offersSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  
      // Fetch seller's listing
      const sellerDoc = await db.collection("sellers").doc(sellerId).get();
      const sellerListing = sellerDoc.exists ? { ...sellerDoc.data(), id: sellerDoc.id } : null;
  
      return { offers, sellerListing };
    } catch (error) {
      console.error("Error fetching offers or seller listing:", error);
      throw new Error("Unable to fetch seller data.");
    }
  };

  handleSignUp = () => {
    const { password, confirmPassword, inputs } = this.state;

    this.setState({
      registerError: ``,
    });

    if (password !== confirmPassword)
      return this.setState({
        registerError: `Please enter same password in both fields.`,
      });
    if (!inputs.email)
      return this.setState({
        registerError: `Please enter correct email in the offer form.`,
      });

    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(inputs.email, password)
        .then((user) => {
          this.onSubmit(null, true, user.user);
        })
        .catch(function (error) {
          console.log(error);
          this.setState({
            registerError: error.message,
          });
        });
    } catch (err) {
      console.log(err);
      this.setState({
        registerError: err.message,
      });
    }
  };

  uploadDocument = async ({ target }) => {
    const files = target.files;
    if (!files.length) return;

    this.setState({ isUploading: true });
    const storage = firebase.storage();
    const offersRef = storage.ref().child(`images/offers`);
    for (const file of files) {
      const userImageRef = offersRef.child(`${(file, moment().utc().unix())}_${file.name}`);
      let document = await userImageRef.put(file);

      const filePath = await firebase.storage().ref().child(document.ref.location.path).getDownloadURL();
      this.setState({
        inputs: {
          ...this.state.inputs,
          documents: [...this.state.inputs.documents, { path: filePath, name: file.name, timestamp: Date.now() }],
        },
      });
    }

    this.setState({ isUploading: false });
    return;
  };

  deleteDocument = (url) => {
    const documents = this.state.inputs.documents.filter((doc) => doc.path !== url);
    this.setState({
      inputs: {
        ...this.state.inputs,
        documents,
      },
    });
  };

dataURItoBlob = (dataURI) => {
  const parts = dataURI.split(',');
  const byteString = atob(parts[1]);
  const mimeString = parts[0].split(':')[1].split(';')[0];
  
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  
  return new Blob([ab], { type: mimeString });
};

  getVideoSource = async (url, blob) => {
    try {
      const currentDate = new Date().getTime();
      this.setState({
        videoSource: blob,
        videoTime: currentDate,
        videoUploading: true,
      });
      if (blob.type === "video/webm") {
        const base64Data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
        const response = await fetch(`${cloudFunctionHost}/convertWebMToMP4`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ data: { data: base64Data } })
        });
        const result = await response.json();
        console.log("Backend response:", result);
  
        const mp4DataURI = result.mp4Data;
        console.log("MP4 data URI received:", mp4DataURI);

        const mp4Blob = this.dataURItoBlob(mp4DataURI);
        const storage = firebase.storage();
        const offersRef = storage.ref().child(`images/offers/recordings/${new Date().getTime()}.mp4`);
        const document = await offersRef.put(mp4Blob);
        const filePath = await firebase.storage().ref().child(document.ref.location.path).getDownloadURL();
        this.setState({
          videoUploading: false,
          videoFileUrl: filePath,
          hideMediaUploadButtons: true,
        });
      } else {
        const extension = blob.type === "video/webm" ? "webm" : "mp4";
        const storage = firebase.storage();
        const offersRef = storage
          .ref()
          .child(`images/offers/recordings/${new Date().getTime()}.${extension}`);
        let document = await offersRef.put(blob);
        const filePath = await firebase
          .storage()
          .ref()
          .child(document.ref.location.path)
          .getDownloadURL();
        
        this.setState({
          videoUploading: false,
          videoFileUrl: filePath,
          hideMediaUploadButtons: true,
        });
      }
    } catch (err) {
      console.error("Error in getVideoSource:", err);
      this.setState({
        videoUploading: false,
        videoFileUrl: null,
        hideMediaUploadButtons: false,
      });
    }
  };

  handleVideoUpload = async (e) => {
    try {
      const url = URL.createObjectURL(e.target.files[0]);
      const currentDate = new Date().getTime();
      const blob = new Blob(e.target.files, {
        type: "video/mp4",
      });
      this.setState({
        videoSource: url,
        videoTime: currentDate,
        videoUploading: true,
      });

      const storage = firebase.storage();
      const offersRef = storage.ref().child(`images/offers/recordings/${new Date().getTime()}`);

      let document = await offersRef.put(blob);

      const filePath = await firebase.storage().ref().child(document.ref.location.path).getDownloadURL();
      this.setState({
        videoUploading: false,
        videoFileUrl: filePath,
        hideMediaUploadButtons: true,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        videoUploading: false,
        videoFileUrl: null,
        hideMediaUploadButtons: false,
      });
    }
  };

  getAudioSource = async (audioDetails, file) => {
    try {
      this.setState({
        audioMsgDetails: audioDetails,
        audioUploading: true,
      });

      const storage = firebase.storage();
      const offersRef = storage.ref().child(`images/offers/recordings/${new Date().getTime()}`);
      let document = await offersRef.put(audioDetails.blob);

      const filePath = await firebase.storage().ref().child(document.ref.location.path).getDownloadURL();
      this.setState({
        audioUploading: false,
        audioFileUrl: filePath,
        hideMediaUploadButtons: true,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        audioUploading: false,
        audioFileUrl: null,
        hideMediaUploadButtons: false,
      });
    }
  };

  secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  handleLoadedMetadata = (e) => {
    const video = this.videoRef.current;
    if (!video) return;
    this.setState({
      selectedVideoTime: video.duration,
    });
  };

  handleAddBuyer = () => {
    const { otherBuyers } = this.state;
    this.setState({
      otherBuyers: [...otherBuyers, { buyerName: '', buyersEmail: '', buyerPhoneNumber: '' }],
    });
  };

  handleDeleteBuyer = (index) => {
    const { otherBuyers } = this.state;
    this.setState({
      otherBuyers: otherBuyers.filter((_, i) => i !== index),
    });
  };

  handleBuyerChange = (index, field, value) => {
    const { otherBuyers } = this.state;
    const updatedBuyers = [...otherBuyers];
    updatedBuyers[index][field] = value;
    this.setState({ otherBuyers: updatedBuyers });
  };


  calculateRealTimeScore = (inputs, seller) => {
    const listingPrice = parseFloat(seller?.listing_price || seller?.current_value || inputs?.actual_price || 0);
    const purchasePrice = parseFloat(inputs.purchasePrice || 0);
    const sellerConcessions = parseFloat(inputs.sellerConcessions || 0); // New field
    const serviceContract = parseFloat(inputs.residentialServiceContract || 0); // New field
    const netPurchasePrice = purchasePrice - sellerConcessions - serviceContract; // Net Purchase Price

    const earnestMoney = parseFloat(inputs.earnestMoney || 0);
    const closingDate = new Date(inputs.closingDate);
    const today = new Date();
    const optionDays = parseInt(inputs.optionDays || 0);
    const titlePolicyExpense = inputs.titlePolicyExpense;
    if (!listingPrice || !purchasePrice) {
      return {
        totalScore: "0.00",
        scoreDetails: [
          { category: "Purchase Price", score: "0.00", total: "54.5", borderColor: '#199bd4' },
          { category: "Closing Date", score: "0", total: "20", borderColor: '#753d90' },
          { category: "Earnest Money", score: "0", total: "15", borderColor: '#d62642 ' },
          { category: "Option Period", score: "0", total: "10", borderColor: '#ed6e41' },
          { category: "Title Policy", score: "0", total: "0.5", borderColor: '#f3a634' },
        ],
      };
    }
    // Scoring Breakdown
    const basePriceScore = Math.min((netPurchasePrice / listingPrice) * 54.5, 54.5);
    const bonusPriceScore = Math.max((netPurchasePrice - listingPrice) / 5000, 0) * 2;
    const purchasePriceScore = basePriceScore + bonusPriceScore;
    
    const closingDays = Math.ceil((closingDate - today) / (1000 * 60 * 60 * 24));
    let closingDateScore = 0;
    if (closingDays <= 15) closingDateScore = 20;
    else if (closingDays <= 30) closingDateScore = 15;
    else if (closingDays <= 45) closingDateScore = 10;
    else closingDateScore = 5;
  
    const earnestMoneyPercentage = (earnestMoney / listingPrice) * 100;
    let earnestMoneyScore = 0;
    if (earnestMoneyPercentage >= 5) earnestMoneyScore = 15;
    else if (earnestMoneyPercentage >= 3.5) earnestMoneyScore = 12;
    else if (earnestMoneyPercentage >= 1.5) earnestMoneyScore = 10;
    else earnestMoneyScore = 5;
  
    let optionPeriodScore = 0;
    if (optionDays === 0) optionPeriodScore = 10;
    else if (optionDays <= 3) optionPeriodScore = 8;
    else if (optionDays <= 5) optionPeriodScore = 5;
  
    const titlePolicyScore = titlePolicyExpense === "Buyer" ? 0.5 : 0;
  
    const totalScore =
      purchasePriceScore +
      closingDateScore +
      earnestMoneyScore +
      optionPeriodScore +
      titlePolicyScore;
  
      const scoreDetails = [
        { category: "Purchase Price", score: purchasePriceScore.toFixed(2), total: "54.5", borderColor: '#199bd4' },
        { category: "Closing Date", score: closingDateScore, total: "20", borderColor: '#753d90' },
        { category: "Earnest Money", score: earnestMoneyScore, total: "15", borderColor: '#d62642' },
        { category: "Option Period", score: optionPeriodScore, total: "10", borderColor: '#ed6e41'},
        { category: "Title Policy", score: titlePolicyScore, total: "0.5", borderColor: '#f3a634' },
      ];
  
    return { totalScore: totalScore.toFixed(2), scoreDetails };
  };  

  toggleLearnScoringModal = () => {
    this.setState((prevState) => ({
      showLearnScoringModal: !prevState.showLearnScoringModal,
    }));
  };

  renderPurchasingDetailsContent = () => {
    const { 
      inputs, 
      inProgress, 
      isUploading, 
      updateMode, 
      propertyAddress, 
      signupCheckResponse, 
      seller, 
      id, 
      agentToggle, 
      lenderInfo, 
      offerType, 
      verbalOfferTerms,
      otherBuyers,
      bac_type
    } = this.state;
    const { history, location, user } = this.props;

    const { totalScore, scoreDetails } = this.calculateRealTimeScore(inputs, seller);

    const numericTotalScore = parseFloat(totalScore);

    const color =
      numericTotalScore < 30
        ? "#ff4d4f" // Bright Red for score < 30
        : numericTotalScore >= 30 && numericTotalScore < 50
        ? "#ff7f50" // Coral for score between 30 and 50
        : numericTotalScore >= 50 && numericTotalScore < 70
        ? "#ffa940" // Orange for score between 50 and 70
        : numericTotalScore >= 70 && numericTotalScore < 85
        ? "#f7b825" // Gold for score between 70 and 85
        : numericTotalScore >= 85 && numericTotalScore < 95
        ? "#7cb305" // Lime Green for score between 85 and 95
        : "#28a745"; // Bright Green for score ≥ 95
        
    let updateOffer = false;
    if (location.search.indexOf("update=") > 0) updateOffer = true;

    return (
      <React.Fragment>
        <h5 className="offersMainHeading">You are one step closer to purchasing {propertyAddress}</h5>
        <p>MAKE AN OFFER allows a BUYER to present a signed offer with outline terms in the Real Estate Purchase Agreement, including addendums, and other sale documents to the SELLER with the intent to purchase the home. This signifies a serious commitment to the SELLER. </p>

        <div className="block-p">
          <p>
            This Intent to purchase is a binding contract and is legally enforced. The Buyer agree that this document is an Offer to Purchase. By clicking MAKE AN OFFER, you agree to Homematchx{" "}
            <a target="_blank" href="/terms_of_use">
              <span className="underline">terms of service</span>
            </a>
            . To learn more{" "}
            <a href="/faqs" target="_blank">
              <span className="underline">visit our FAQ</span>
            </a>
            .
          </p>
        </div>
        <h5 className="offersMainHeading">Please provide the basic information about the purchase terms and upload your offer.</h5>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            {verbalOfferTerms?.is_verbal_offer_terms_allowed && verbalOfferTerms.is_verbal_offer_terms_allowed === true && <div className="col-lg-12 col-lg-offset-4 mb-2">
              <FormGroup className="pt-14 mb-0">
                <Label className="label-font">Offer Type:</Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input
                    type="radio"
                    name="offerType"
                    value="Written"
                    checked={offerType === 'Written'}
                    onChange={(e) => {
                      this.setState({ offerType: e.target.value });
                    }}
                  />{" "}
                  <span>Written</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input
                    type="radio"
                    name="offerType"
                    value="Verbal"
                    checked={offerType === 'Verbal'}
                    onChange={(e) => {
                      this.setState({ offerType: e.target.value });
                    }}
                  />{" "}
                  <span>Verbal</span>
                </Label>
              </FormGroup>
            </div>}
            {offerType === 'Verbal' && <>
              <div className="col-lg-6">
                <FormGroup className="form-group">
                  <Label className="label-font">Cash Type</Label>
                  <select className="form-control custom-select" name="cashType" value={inputs.cashType || ""} onChange={this.onChange} required>
                    <option value="">Select Cash Type</option>
                    <option value="Liquid Cash">Liquid Cash</option>
                    <option value="Hard Money Loan">Hard Money Loan</option>
                  </select>
                </FormGroup>
              </div>
              <div className="col-lg-6"/>
            </>}
            <div className="col-lg-6 col-lg-offset-6">
              {offerType === 'Written' && <>
                <FormGroup className="form-group">
                  <Label className="label-font">Financing Type</Label>
                  <select className="form-control custom-select" name="financingType" value={inputs.financingType || ""} onChange={this.onChange} required>
                    <option value="">Select Financing Type</option>
                    <option value="Cash">Cash</option>
                    <option value="USDA">USDA</option>
                    <option value="Conventional">Conventional</option>
                    <option value="FHA">FHA</option>
                    <option value="VA">VA</option>
                    <option value="Seller Finance">Seller Finance</option>
                  </select>
                </FormGroup>
                <div className="row">
                  <div className="col-lg-12 col-lg-offset-4 pl-0">
                    <FormGroup className="pt-14 mb-0">
                      <Label className="label-font">Would you like to add lender information:</Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="lenderInfo"
                          checked={lenderInfo}
                          onChange={(e) => {
                            this.setState({ lenderInfo: true });
                          }}
                        />{" "}
                        <span>Yes</span>
                      </Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="lenderInfo"
                          checked={!lenderInfo}
                          onChange={(e) => {
                            this.setState({ lenderInfo: false });
                          }}
                        />{" "}
                        <span>No</span>
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                {lenderInfo && (
                  <>
                    <FormGroup className="form-group pt-14">
                      <Label className="label-font">Lender Company</Label>
                      <Input name="lenderCompany" value={inputs.lenderCompany || ""} onChange={this.onChange} className="input-text custom-input" placeholder="Lender Company" required={lenderInfo ? true : false} />
                    </FormGroup>
                    <FormGroup className="form-group pt-14">
                      <Label className="label-font">Lender Name</Label>
                      <Input name="lenderName" onChange={this.onChange} className="input-text custom-input" placeholder="Lender Name" required={lenderInfo ? true : false} />
                    </FormGroup>
                    <FormGroup className="form-group pt-14">
                      <Label className="label-font">Lender Email</Label>
                      <Input type="email" name="lenderEmail" onChange={this.onChange} className="input-text custom-input" placeholder="Lender Email" required={lenderInfo ? true : false} />
                    </FormGroup>
                    <FormGroup className="form-group pt-14">
                      <Label className="label-font">Lender Phone Number</Label>
                      <Input type="text" name="lenderPhoneNumber" onChange={this.onChange} className="input-text custom-input" placeholder="Lender Phone Number" required={lenderInfo ? true : false} />
                    </FormGroup>
                  </>
                )}
              </>
              }
              <div className="row">
                <div className={offerType === 'Written' ? "col-lg-6 pl-0" : "col-lg-12 pl-0 pr-0"}>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Purchase Price:</Label>
                    <Input name="purchasePrice" value={inputs.purchasePrice || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                    {offerType === "Verbal" &&
                      Number(inputs?.purchasePrice) < Number(verbalOfferTerms?.minimum_offer_price) &&
                      inputs.purchasePrice !== "" && (
                        <p>
                          {`The entered purchase price is lower than the seller's minimum offer price of `}
                          <strong>
                            {Number(verbalOfferTerms?.minimum_offer_price).toLocaleString()}
                          </strong>
                          {`. Your offer may be rejected.`}
                        </p>
                      )}
                  </FormGroup>
                  {offerType === 'Written' && <FormGroup className="form-group pt-14">
                    <Label className="label-font">Finance Amount:</Label>
                    <Input name="financeAmount" disabled={inputs.financingType === 'Cash'} value={inputs.financingType === 'Cash' ? "" : inputs.financeAmount || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                  </FormGroup>}
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">{offerType === 'Written' ? 'Earnest' : 'Deposit'} Money:</Label>
                    <Input name="earnestMoney" value={inputs.earnestMoney || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                    {offerType === "Verbal" &&
                      Number(inputs.earnestMoney) < Number(verbalOfferTerms?.deposit_money) &&
                      inputs.earnestMoney !== "" && (
                        <p>
                          {`The entered ${
                            offerType === "Written" ? "earnest" : "deposit"
                          } money is lower than the seller's minimum requirement of `}
                          <strong>
                            {Number(verbalOfferTerms?.deposit_money).toLocaleString()}
                          </strong>
                          {`. Your offer may be rejected.`}
                        </p>
                      )}
                  </FormGroup>
                  {offerType === 'Written' && <FormGroup className="form-group pt-14">
                    <Label className="label-font">Option Fee:</Label>
                    <Input name="optionFee" value={inputs.optionFee || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                  </FormGroup>}
                </div>
                {offerType === 'Written' && <div className="col-lg-6 mobile-pl-0">
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Down Payment:</Label>
                    <Input name="downPayment" disabled={inputs.financingType === 'Cash'} value={inputs.financingType === 'Cash'?  inputs.purchasePrice : inputs.downPayment || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Seller Concessions:</Label>
                    <Input name="sellerConcessions" value={inputs.sellerConcessions || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Residential Service Contract:</Label>
                    <Input name="residentialServiceContract" value={inputs.residentialServiceContract || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Option Days</Label>
                    <Input name="optionDays" value={inputs.optionDays || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                  </FormGroup>
                </div>}
              </div>
            </div>
          </div>
          <div className="row">
            {offerType === 'Written' && <div className="col-lg-8 col-lg-offset-4 mb-8">
              <FormGroup className="pt-14 mb-0">
                <Label className="label-font">Title Policy Expense:</Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label check>
                  <Input type="radio" name="titlePolicyExpense" value="Seller" checked={inputs.titlePolicyExpense === "Seller"} onChange={this.onChange} /> <span>Seller</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label check>
                  <Input type="radio" name="titlePolicyExpense" value="Buyer" checked={inputs.titlePolicyExpense === "Buyer"} onChange={this.onChange} /> <span>Buyer</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label check>
                  <Input type="radio" name="titlePolicyExpense" value="Split" checked={inputs.titlePolicyExpense === "Split"} onChange={this.onChange} /> <span>Split</span>
                </Label>
              </FormGroup>
            </div>}
            <div className="col-lg-6">
              <FormGroup className="form-group pt-14">
                <Label for="exampleDate" className="label-font">
                  Closing Date
                </Label>
                <Input type="date" name="closingDate" value={moment(inputs.closingDate, "YYYY/MM/DD").format("YYYY-MM-DD") || ""} min={updateOffer ? moment("1970/01/01").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")} onChange={this.onChange} />
                {offerType === "Verbal" &&  verbalOfferTerms?.closing_date?.seconds &&
                  inputs.closingDate &&
                  moment(inputs.closingDate).isAfter(
                    moment(verbalOfferTerms.closing_date.seconds * 1000)
                  ) && (
                    <p>
                      The entered closing date is later than the seller's required closing
                      date of{" "}
                      <strong>
                        {moment(
                          verbalOfferTerms.closing_date.seconds * 1000
                        ).format("MMMM DD, YYYY")}
                      </strong>
                      . Your offer may be rejected.
                    </p>
                  )}
              </FormGroup>
            </div>
            <div className="col-lg-2 col-lg-offset-4">
              <FormGroup className="form-group pt-14">
                <Label className="label-font"># of Days</Label>
                <Input name="numberOfDays" value={inputs.numberOfDays || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
              </FormGroup>
            </div>
            {offerType === 'Written' && <div className="col-lg-8 col-lg-offset-4">
              <FormGroup className="pt-14 mb-0">
                <Label className="label-font">Possession:</Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="possession" value="Upon Close & Fund" checked={inputs.possession === "Upon Close & Fund"} onChange={this.onChange} /> <span>Upon Close & Fund</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="possession" value="Leaseback" checked={inputs.possession === "Leaseback"} onChange={this.onChange} /> <span>Leaseback</span>
                </Label>
              </FormGroup>
            </div>}
          </div>
          {offerType === 'Written' && <>
            <div className="row">
              <div className="col-lg-3 col-lg-offset-9">
                <FormGroup className="form-group pt-14">
                  <Label className="label-font">If Leaseback, how many days:</Label>
                  <Input name="leasebackDays" value={inputs.leasebackDays || ""} onChange={this.onChange} className="input-text custom-input" placeholder="0" />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-lg-offset-6">
                <FormGroup className="form-group pt-14">
                  <Label className="label-font">Non Realty Item</Label>
                  <Input name="nonRealtyItem" value={inputs.nonRealtyItem || ""} onChange={this.onChange} className="input-text custom-input" placeholder="Refrigerator, Washer, Dryer," />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-lg-offset-4">
                <FormGroup className="pt-14 mb-0">
                  <Label className="label-font">Sale of Other Property:</Label>
                </FormGroup>
                <FormGroup check className="radio-btn">
                  <Label>
                    <Input type="radio" name="saleOfOtherProperty" value="Yes" checked={inputs.saleOfOtherProperty === "Yes"} onChange={this.onChange} /> <span>Yes</span>
                  </Label>
                </FormGroup>
                <FormGroup check className="radio-btn">
                  <Label>
                    <Input type="radio" name="saleOfOtherProperty" value="No" checked={inputs.saleOfOtherProperty === "No"} onChange={this.onChange} /> <span>No</span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </>}
          <div className="row">
            {offerType === 'Written' && <div className="col-lg-8 col-lg-offset-4">
              <FormGroup className="pt-14 mb-0">
                <Label className="label-font">Right To Terminate Due to Lender’s Appraisal</Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="rightToTerminate" value="Full Waiver" checked={inputs.rightToTerminate === "Full Waiver"} onChange={this.onChange} /> <span>Full Waiver</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="rightToTerminate" value="Partial Waiver" checked={inputs.rightToTerminate === "Partial Waiver"} onChange={this.onChange} /> <span>Partial Waiver</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="rightToTerminate" value="Additional Right To Terminate" checked={inputs.rightToTerminate === "Additional Right To Terminate"} onChange={this.onChange} /> <span>Additional Right To Terminate</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="rightToTerminate" value="No Waiver" checked={inputs.rightToTerminate === "No Waiver"} onChange={this.onChange} /> <span>No Waiver</span>
                </Label>
              </FormGroup>
            </div>}
            <div className="col-lg-7 col-lg-offset-5">
              <FormGroup className="form-group pt-14">
                <Label className="label-font">Comments relevant to the offer and use in email body</Label>
                <Input type="textarea" name="comments" value={inputs.comments || ""} onChange={this.onChange} className="input-text input-area" placeholder="Add comments" required />
              </FormGroup>
            </div>
          </div>
          {/* Expire date */}
          <div className="row">
            <div className="col-lg-3 col-lg-offset-4">
              <FormGroup className="pt-14 mb-0">
                <Label className="label-font">Does Offer Expire?</Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input
                    type="radio"
                    name="doesExpire"
                    value="yes"
                    checked={this.state.doesExpire === "yes"}
                    onChange={(e) => {
                      this.setState({
                        doesExpire: e.target.value,
                      });
                    }}
                  />{" "}
                  <span>Yes</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input
                    type="radio"
                    name="doesExpire"
                    value="no"
                    checked={this.state.doesExpire === "no"}
                    onChange={(e) => {
                      this.setState({
                        doesExpire: e.target.value,
                        inputs: {
                          ...inputs,
                          offer_expire_date: "",
                        },
                      });
                    }}
                  />{" "}
                  <span>No</span>
                </Label>
              </FormGroup>
            </div>
            {this.state.doesExpire === "yes" && (
              <div className="col-lg-4 col-lg-offset-4">
                <FormGroup className="form-group pt-14">
                  <Label for="offer_expire_date" className="label-font">
                    Offer Expire Date
                  </Label>
                  <Input
                    type="date"
                    name="offer_expire_date"
                    value={moment(inputs.offer_expire_date, "YYYY/MM/DD").format("YYYY-MM-DD") || ""}
                    // min={
                    //   updateOffer
                    //     ? moment("1970/01/01").format("YYYY-MM-DD")
                    //     : moment().format("YYYY-MM-DD")
                    // }
                    onChange={this.onChange}
                  />
                </FormGroup>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-lg-6 col-lg-offset-6">
              <div className="btn-group" style={{ marginTop: 40, marginBottom: 20 }}>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      agentToggle: 0,
                    })
                  }
                  className={agentToggle === 0 ? "makeOffer-btnGroup-active" : "makeOffer-btnGroup-inactive"}
                  style={{ paddingRight: 20 }}
                >
                  With Buyers Agent
                </button>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      agentToggle: 1,
                    })
                  }
                  className={agentToggle === 1 ? "makeOffer-btnGroup-active" : "makeOffer-btnGroup-inactive"}
                >
                  Without Buyers Agent
                </button>
              </div>

              {/* <h5 className="offersMainHeading agent">Buyers Agent Information</h5> */}

              {agentToggle === 0 ? (
                <>
                  {/* <div className="col-lg-12 col-lg-offset-4 mb-2"> */}
                    <FormGroup className="pt-14 mb-0">
                      <Label className="label-font">Buyers Agent Compensation Type:</Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="bac_type"
                          value="Fixed"
                          checked={bac_type === 'Fixed'}
                          onChange={(e) => {
                            this.setState({ bac_type: e.target.value });
                          }}
                        />{" "}
                        <span>Fixed</span>
                      </Label>
                    </FormGroup>
                    <FormGroup check className="radio-btn">
                      <Label>
                        <Input
                          type="radio"
                          name="bac_type"
                          value="Percentage"
                          checked={bac_type === 'Percentage'}
                          onChange={(e) => {
                            this.setState({ bac_type: e.target.value });
                          }}
                        />{" "}
                        <span>Percentage</span>
                      </Label>
                    </FormGroup>
                  {/* </div> */}
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Buyers Agent Compensation</Label>
                    <Input
                      name="bac"
                      value={inputs.bac || ""}
                      onChange={this.onChange}
                      className="input-text custom-input"
                      placeholder=""
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Agent Name</Label>
                    <Input name="agentName" defaultValue={updateOffer ? inputs.agentName : user?.realtor_name} onChange={this.onChange} className="input-text custom-input" placeholder="First and Last Name" required />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Agent Email</Label>
                    <Input type="email" name="email" defaultValue={updateOffer ? inputs.email : user?.realtor_email} onChange={this.onChange} className="input-text custom-input" placeholder="Email" required />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Agent License Number</Label>
                    <Input name="licenseNo" value={inputs.licenseNo || ""} onChange={this.onChange} className="input-text custom-input" placeholder="License Number" required />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Brokerage Name</Label>
                    <Input name="brokerageName" value={inputs.brokerageName || ""} onChange={this.onChange} className="input-text custom-input" placeholder="Brokerage Name " required />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Phone Number</Label>
                    <Input
                      name="phoneNumber"
                      defaultValue={updateOffer ? inputs.phoneNumber : user?.realtor_phone}
                      onChange={this.onChange}
                      className="input-text custom-input"
                      // type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                      required
                    />
                  </FormGroup>

                </>
              ) : (
                <>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Name</Label>
                    <Input name="name" defaultValue={updateOffer ? inputs.name : user?.realtor_name} onChange={this.onChange} className="input-text custom-input" placeholder="Name" required />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Email</Label>
                    <Input type="email" name="email" defaultValue={updateOffer ? inputs.email : user?.realtor_email} onChange={this.onChange} className="input-text custom-input" placeholder="Email" required />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Phone Number</Label>
                    <Input
                      name="phoneNumber"
                      defaultValue={updateOffer ? inputs.phoneNumber : user?.realtor_phone}
                      onChange={this.onChange}
                      className="input-text custom-input"
                      // type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                      required
                    />
                  </FormGroup>
                </>
              )}
              {/* <div className="make-offer-heading">Would you like to share offer status with buyer and/or others?</div> */}
              {agentToggle === 0 ? (
                <>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Buyer Name</Label>
                    <Input name="buyerName" defaultValue={inputs.buyerName} onChange={this.onChange} className="input-text custom-input" placeholder="Name" />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Buyer Email</Label>
                    <Input type="email" name="buyersEmail" defaultValue={inputs.buyersEmail} onChange={this.onChange} className="input-text custom-input" placeholder="Email" />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Buyer Phone Number</Label>
                    <Input
                      name="buyerPhoneNumber"
                      defaultValue={inputs.buyerPhoneNumber}
                      onChange={this.onChange}
                      className="input-text custom-input"
                      // type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                    />
                  </FormGroup>
                  <button type="button" onClick={this.handleAddBuyer} className="upload-btn mb-0">
                    Add More Buyer
                  </button>
                  {otherBuyers?.map((buyer, index) => (
                    <div key={index} className="additional-buyer">
                      <FormGroup className="form-group pt-14">
                        <Label className="label-font">Buyer Name</Label>
                        <Input
                          name={`buyerName-${index}`}
                          value={buyer.buyerName}
                          onChange={(e) =>
                            this.handleBuyerChange(index, 'buyerName', e.target.value)
                          }
                          className="input-text custom-input"
                          placeholder="Name"
                        />
                      </FormGroup>
                      <FormGroup className="form-group pt-14">
                        <Label className="label-font">Buyer Email</Label>
                        <Input
                          type="email"
                          name={`buyersEmail-${index}`}
                          value={buyer.buyersEmail}
                          onChange={(e) =>
                            this.handleBuyerChange(index, 'buyersEmail', e.target.value)
                          }
                          className="input-text custom-input"
                          placeholder="Email"
                        />
                      </FormGroup>
                      <FormGroup className="form-group pt-14">
                        <Label className="label-font">Buyer Phone Number</Label>
                        <Input
                          name={`buyerPhoneNumber-${index}`}
                          value={buyer.buyerPhoneNumber}
                          onChange={(e) =>
                            this.handleBuyerChange(index, 'buyerPhoneNumber', e.target.value)
                          }
                          className="input-text custom-input"
                          maxLength="12"
                          placeholder="123-456-7890"
                        />
                      </FormGroup>
                      <button
                        type="button"
                        onClick={() => this.handleDeleteBuyer(index)}
                        className="cancel-request"
                      >
                        Delete Buyer
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Phone Number</Label>
                    <Input
                      name="secondPhoneNumber"
                      defaultValue={inputs.secondPhoneNumber}
                      onChange={this.onChange}
                      className="input-text custom-input"
                      // type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Label className="label-font">Phone Number</Label>
                    <Input
                      name="thirdPhoneNumber"
                      defaultValue={inputs.thirdPhoneNumber}
                      onChange={this.onChange}
                      className="input-text custom-input"
                      // type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                    />
                  </FormGroup>
                </>
              )}
              <FormGroup className="pt-14 mb-0">
                <Label className="label-font">How would you like to be notified of your offer status?</Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="notifiedBy" value="Text" checked={inputs.notifiedBy === "Text"} onChange={this.onChange} /> <span>Text</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="notifiedBy" value="Email" checked={inputs.notifiedBy === "Email"} onChange={this.onChange} /> <span>Email</span>
                </Label>
              </FormGroup>
              <FormGroup check className="radio-btn">
                <Label>
                  <Input type="radio" name="notifiedBy" value="Both" checked={inputs.notifiedBy === "Both"} onChange={this.onChange} /> <span>Both</span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h5 className="offersMainHeading agent">Upload Offer Package</h5>
              {/* <FormGroup className="pt-14 mb-0">
                <Label className="label-font">How would you like to be notified of your offer status?</Label>
              </FormGroup> */}
              {inputs.documents.map((doc, key) => (
                <FormGroup className="download" key={key}>
                  <Label>{doc.name}</Label>
                  <img src={require("../assets/icons/cross-red.png")} onClick={() => this.deleteDocument(doc.path)} />
                </FormGroup>
              ))}
              <FormGroup className="custom-form-group">
                <label htmlFor={isUploading ? "" : "file-picker"} className="upload-btn-wrapper">
                  <div className="upload-btn">{isUploading ? "Uploading..." : "Add Documents"}</div>
                  <input type="file" multiple id="file-picker" onChange={this.uploadDocument} name="myfile" />
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <h5 className="offersMainHeading agent">Upload Media (optional)</h5>
              <Label className="label-font">Buyers Agent can record a video or an audio message to talk a little more about the offer and give it a personal touch. The file can be reproduced on the offer page.</Label>
              {!this.state.hideMediaUploadButtons && !this.state.videoFileUrl && !this.state.audioFileUrl && (
                <div className="flex-row flex-wrap">
                  <button
                    className="button-with-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      this.recordVideoModalToggle();
                    }}
                  >
                    <img className="video-icon" src={require("../assets/icons/video.png")} />
                    Record Video
                  </button>
                  <button
                    className="button-with-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      this.recordAudioModalToggle();
                    }}
                  >
                    <img className="mic-icon" src={require("../assets/icons/mic.png")} />
                    Record Audio
                  </button>
                  <FormGroup className="custom-form-group-upload">
                    <label htmlFor={"video-picker"} className="video-upload-btn-wrapper">
                      <div className="upload-btn">
                        <img className="upload-icon" src={require("../assets/icons/upload.png")} />
                        {"Upload File"}
                      </div>
                      <input type="file" accept="video/mp4,video/x-m4v,video/*" id="video-picker" name="selectedVideo" onChange={(e) => this.handleVideoUpload(e)} />
                    </label>
                  </FormGroup>
                </div>
              )}
              <div>
                {this.state.audioUploading && <p>Uploading...</p>}
                {this.state.audioFileUrl && (
                  <>
                    {isSafari ? (
                      <audio controls>
                        <source src={this.state.audioFileUrl} type="audio/ogg" />
                        <source src={this.state.audioFileUrl} type="audio/mpeg" />
                      </audio>
                    ) : (
                      <audio controls src={this.state.audioFileUrl} />
                    )}
                    <button
                      className="button-with-icon delete-file-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          audioFileUrl: null,
                          hideMediaUploadButtons: false,
                          videoFileUrl: null,
                        });
                      }}
                    >
                      <img className="mic-icon" src={require("../assets/icons/bin.png")} />
                      Delete file
                    </button>
                  </>
                )}
              </div>
              <div>
                {this.state.videoUploading && <p>Uploading...</p>}
                {this.state.videoFileUrl && (
                  <>
                    <video className="preview-video-frame" ref={this.videoRef} controls src={this.state.videoFileUrl} onLoadedMetadata={(e) => this.handleLoadedMetadata(e)} />
                    {/* <p>{this.secondsToHms(this.state.selectedVideoTime)}</p> */}
                    <button
                      className="button-with-icon delete-file-btn"
                      onClick={() =>
                        this.setState({
                          videoFileUrl: null,
                          audioFileUrl: null,
                          videoTime: null,
                          hideMediaUploadButtons: false,
                        })
                      }
                    >
                      <img className="mic-icon" src={require("../assets/icons/bin.png")} />
                      Delete file
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row mt--100">
            <div className="col-lg-12">
              <h5 className="offersMainHeading concent">Consent</h5>
              <div className="custom-control custom-checkbox m-vertical-7">
                <input
                  required
                  className="custom-control-input propertyFilterChkBox"
                  type="checkbox"
                  onClick={(e) => {
                    this.setState({
                      globalOfferDataAccess: e.target.checked,
                    });
                  }}
                  id={"consent"}
                  value={this.state.globalOfferDataAccess}
                />
                <label className="custom-control-label label-font" htmlFor="consent">
                  {" "}
                  By checking this box, I hereby grant my consent to publicly share the offer highlights made on behalf of my client once the listed property is closed. I fully acknowledge and appreciate that this dissemination of information is intended to provide valuable insights into each listed property. Furthermore, I understand that all personal and confidential information will be handled in a professional manner, adhering strictly to the Privacy Policy.
                </label>
              </div>
            </div>
          </div>
          {this.state.updateMode && <div className="row mt--100">
            <div className="col-lg-12">
              <h5 className="offersMainHeading concent">Notifications</h5>
              <div className="amend-notifications-toogle">
                <Label>Do you want to send sms and email notification?</Label>
                <Switch
                  checked={this.state.allowSmsEmailNoti}
                  onChange={(checked) => {
                    this.setState({
                      allowSmsEmailNoti: checked,
                    });
                  }}
                  onColor="#28a745"
                  offColor="#ff214f"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={22}
                  width={44}
                />
              </div>
            </div>
          </div>}
          <div className="row submit-btns">
            <div className="col-lg-12">
              {this.props.user?.uid === seller.seller_id && !this.state.isOfferUpdate ? (
                <button
                  type="button"
                  // disabled={true}
                  className={`submit-offer submit-offer-seller`}
                  onMouseEnter={() => {
                    this.setState({
                      hideSubmitButton: true,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      hideSubmitButton: false,
                    });
                  }}
                >
                  Submit Offer
                </button>
              ) : (
                <button type="submit" disabled={inProgress || isUploading || this.state.videoUploading || this.state.audioUploading} className={`submit-offer `}>
                  {inProgress ? "Submitting..." : updateMode ? (updateOffer ? "Update Offer" : "Resubmit Offer") : "Submit Offer"}
                </button>
              )}
              {this.state.hideSubmitButton ? (
                <>
                  <img src={swirlArrow} className="d-inline submit-offer-seller-image" />
                  <p className="submit-offer-seller-text d-inline">You can not submit offer your on own Property!</p>
                </>
              ) : null}

              {this.props.user?.uid === seller.seller_id && !this.state.isOfferUpdate ? null : (
                <button
                  type="button"
                  disabled={inProgress}
                  className="cancel-request"
                  onClick={() => {
                    if (updateOffer) history.push(`/offers/${inputs.id}`);
                    else history.push(`/sellers/${id}`);
                  }}
                >
                  {updateMode ? (updateOffer ? "Cancel Update" : "Cancel Resubmission") : "Cancel Request"}
                </button>
              )}
              <button
                type="button"
                className="preview-mail-btn"
                onClick={() => {
                  localStorage["email-preview"] = JSON.stringify({
                    inputs: {...inputs, bac_type: this.state.bac_type},
                    seller: seller,
                    id: id,
                    fromNewOffer: true,
                  });
                }}
              >
                <Link
                  target="_blank"
                  to={{
                    pathname: "/email-preview",
                  }}
                  className="preview-link"
                >
                  Preview email
                </Link>
              </button>
              <button
                type="button"
                className="cancel-request"
                onClick={() => {
                  this.previewOfferScoreModalToggle()
                }}
              >
                Preview Offer Score
              </button>
            </div>
          </div>
        </form>

        <Modal isOpen={this.state.recordAudioModal} size="lg" fade={false} toggle={this.recordAudioModalToggle}>
          <ModalHeader className="modal-header" toggle={this.recordAudioModalToggle}></ModalHeader>
          <ModalBody className="video-recorder-modal-body">
            <Container className="video-recorder-container">
              <Row>
                <Col>
                  <h5 className="record-Video-heading">Record Audio</h5>
                  <RecordAudio getAudioSource={this.getAudioSource} modalToggle={this.recordAudioModalToggle} />
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.recordVideoModal} size="lg" fade={false} toggle={this.recordVideoModalToggle}>
          <ModalHeader className="modal-header" toggle={this.recordVideoModalToggle}></ModalHeader>
          <ModalBody className="video-recorder-modal-body">
            <Container className="video-recorder-container">
              <Row>
                <Col>
                  <h5 className="record-Video-heading">Record Video</h5>
                  <RecordVideo getVideoSource={this.getVideoSource} modalToggle={this.recordVideoModalToggle} />
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.signupCheckModal} size="md" fade={false} toggle={this.signupCheckModalToggle}>
          <ModalHeader className="modal-header" toggle={this.signupCheckModalToggle}></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="form-content-box content-box-signupCheck">
                    <div className="details">
                      <Link to="/">
                        <img src="/img/logos/black-logo.png" alt="black-logo" className="cm-logo" />
                      </Link>
                      {signupCheckResponse ? <p>Set password for your account associated with {this.state.inputs.email}</p> : <p>Do you want to create account with your email: {this.state.inputs.email}</p>}
                      {signupCheckResponse ? (
                        <form>
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              placeholder="Password"
                              className="input-text"
                              onChange={(e) =>
                                this.setState({
                                  password: e.target.value,
                                })
                              }
                              value={this.state.inputs["password"]}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              placeholder="Confirm Password"
                              className="input-text"
                              onChange={(e) =>
                                this.setState({
                                  confirmPassword: e.target.value,
                                })
                              }
                            />
                          </div>
                          <p className="text-danger">{this.state.registerError}</p>
                          <div className="form-group mb-0">
                            <button type="button" className="btn-md button-theme btn-block mb-20" onClick={this.handleSignUp}>
                              Register
                            </button>
                            <a
                              onClick={() => {
                                this.onSubmit(null, true);
                                this.signupCheckModalToggle();
                              }}
                            >
                              No thanks, I was just send the offer.
                            </a>
                          </div>
                        </form>
                      ) : (
                        <div>
                          <button
                            className="yes-btn"
                            onClick={() => {
                              this.setState({
                                signupCheckResponse: true,
                              });
                            }}
                          >
                            Yes
                          </button>
                          <button
                            className="cancel-btn"
                            onClick={() => {
                              this.onSubmit(null, true);
                              this.signupCheckModalToggle();
                            }}
                          >
                            No
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        {this.state.showOfferScoreWidget && 
          <div className="ai-score-widget-container">
            <AiScoreDoughnutChart
              value={totalScore}
              total={100}
              color={color}
              title="Score"
            />
          </div>}
        <Modal
          isOpen={this.state.previewOfferScoreModal}
          size="md"
          fade={false}
          toggle={this.previewOfferScoreModalToggle}
        >
          <ModalHeader className="modal-header preview-offer-score-modal-head" toggle={this.previewOfferScoreModalToggle}>
            Your Offer Score Preview
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="form-content-box content-box-preview-offer">
                    <div className="text-center">
                      <div
                        className="doughnut-chart-wrapper"
                        style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}
                      >
                        <AiScoreDoughnutChart
                          value={totalScore}
                          total={100}
                          color={color}
                          title="Score"
                        />
                      </div>
                      <p>See how your offer ranks based on key seller considerations.</p>

                      <div className="score-breakdown">
                        <div className="total-scr"
                        >
                          <span>Total Score:</span>
                          <span style={{ fontWeight: "600" }}>{totalScore}/100</span>
                        </div>
                        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                          {scoreDetails.map((detail, index) => (
                            <li
                              key={index}
                              className="real-time-scr"
                              style={{
                                borderBottomColor: detail.borderColor,
                                borderBottomWidth: 3,
                                borderBottomStyle: "solid",
                              }}
                            >
                              <span style={{ fontWeight: "500", fontSize: "14px" }}>{detail.category}</span>
                              <span style={{ fontWeight: "600", fontSize: "14px" }}>
                                <span style={{color: detail.borderColor }}>{detail.score}</span>/{detail.total}
                              </span>
                            </li>
                          ))}
                        </ul>
                        <div className="preview-offer-score-tooggle">
                          <Label className="d-block">Show Offer Score widget</Label>
                          <Switch
                            checked={this.state.showOfferScoreWidget}
                            onChange={(checked) => {
                              this.setState({
                                showOfferScoreWidget: checked,
                              });
                            }}
                            onColor="#28a745"
                            offColor="#ff214f"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={22}
                            width={44}
                          />
                        </div>
                      </div>
                      <button
                        className="learn-scoring-button-make-offer"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.toggleLearnScoringModal();
                        }}
                      >
                        Learn How We Score
                      </button>

                      <p
                        className="powered-by-offeraide-sm-txt"
                      >
                        Powered by <span className="powered-by-offerAIde">OfferAIde</span>: Your Smart Offer Scoring Assistant
                      </p>
                      <div className="action-buttons text-center" style={{ marginTop: "20px" }}>
                        <button
                          className="gotit-btn"
                          style={{ marginRight: "10px" }}
                          onClick={this.previewOfferScoreModalToggle}
                        >
                          Got It!
                        </button>
                        <button
                          className="submit-anyway-btn"
                          onClick={() => {
                            this.previewOfferScoreModalToggle();
                            this.onSubmit(null, true);
                          }}
                        >
                          Submit Offer Anyway
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        <ScoringModal
          showModal={this.state.showLearnScoringModal}
          hideModal={this.toggleLearnScoringModal}
        />
      </React.Fragment>
    );
  };

  render() {
    const { seller } = this.state; 

    if (!seller) {
      return <Loader type="Rings" color="#ff214f" height="80%" width="100%" />;
    }
    return (
      <React.Fragment>
        <SubBanner title="Prepare an Offer" image="/images/mx4mSkK9zeo.jpg" {...this.props} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">{this.renderPurchasingDetailsContent()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MakeOffer;
